import { MockQuoteField, QuoteField } from '../quotes/quoteField';

export enum RunField {
    ID = 'id',
    CREATED = 'created',
    TYPE = 'type',
    STATUS = 'status',
    NUMBER = 'number',
    TTN_STATUS = 'ttnStatus',
    TTN_NUMBER = 'ttnNumber',
    TTN_DATE = 'ttnDate',
    LOGISTIC_MANAGER = 'logisticManager',
    COMMERCIAL_MANAGER = 'commercialManager',
    DESCRIPTION = 'description',
    IS_BULK = 'isBulk',

    CLIENT_COMPANY = 'clientCompany',
    CLIENT = 'client',
    CLIENT_CONTRACT = 'clientContract',
    CLIENT_CONTRACT_NO = 'clientContractNo',
    CLIENT_CONTACT = 'clientContact',
    CLIENT_PRICE = 'clientPrice',
    CLIENT_PAYMENT_METHOD = 'clientPaymentMethod',
    CLIENT_PAYMENT_TYPE = 'clientPaymentType',
    CLIENT_PAYMENT_DELAY = 'clientPaymentDelay',
    CLIENT_PLANNED_PAYMENT_DATE = 'clientPlannedPaymentDate',
    CLIENT_PAYMENT_DATE = 'clientPaymentDate',
    CLIENT_EXPENSES = 'clientExpenses',
    CLIENT_PAYMENT_DELAY_TYPE = 'clientPaymentDelayType',

    CARRIER_COMPANY = 'carrierCompany',
    CARRIER = 'carrier',
    CARRIER_CONTRACT = 'carrierContract',
    CARRIER_CONTRACT_NO = 'carrierContractNo',
    CARRIER_CONTACT = 'carrierContact',
    CARRIER_PRICE = 'carrierPrice',
    CARRIER_PAYMENT_METHOD = 'carrierPaymentMethod',
    CARRIER_PAYMENT_TYPE = 'carrierPaymentType',
    CARRIER_PAYMENT_DELAY = 'carrierPaymentDelay',
    CARRIER_PLANNED_PAYMENT_DATE = 'carrierPlannedPaymentDate',
    CARRIER_PAYMENT_DATE = 'carrierPaymentDate',
    CARRIER_EXPENSES = 'carrierExpenses',
    CARRIER_PAYMENT_DELAY_TYPE = 'carrierPaymentDelayType',

    DRIVER = 'driver',
    DRIVER_PHONE = 'driverPhone',
    TRUCK = 'truck',
    TRAILER = 'trailer',

    ROUTE = 'route',
    GROUPED_SORTED_ROUTE = 'groupedSortedRoute',

    LOADING_DATE = 'loadingDate',
    LOADING_STORAGE = 'loadingStorage',
    LOADING_ADDRESS = 'loadingAddress',

    UNLOADING_DATE = 'unloadingDate',
    UNLOADING_STORAGE = 'unloadingStorage',
    UNLOADING_ADDRESS = 'unloadingAddress',

    QUOTE_NUMBER = 'quoteNumber',

    CARGO_PRICE = 'cargoPrice',
    CARGO_WEIGHT = 'cargoWeight',

    STORAGE_STATUS = 'storageStatus',

    IS_DOMESTIC_RUN = 'isDomesticRun',
    UNLOADING_POINT_COUNT = 'unloadingPointCount',
}

export enum MockRunField {
    CONTRACT = 'contract',
    NDS_MODE = 'ndsMode',
    PAYMENT_TYPE = 'paymentType',
    PAYMENT_DELAY = 'paymentDelay',
    PLANNED_PAYMENT_DATETIME = 'plannedPaymentDatetime',
    PAYMENT_DELAY_TYPE = 'paymentDelayType',
    PAYMENT_METHOD = 'paymentMethod',
}

export const CLIENT_CONTRACT_DEPENDENT_FIELDS = new Map<string, string>([
    [MockRunField.CONTRACT, RunField.CLIENT_CONTRACT],
    [MockQuoteField.PAYMENT_METHOD, QuoteField.CLIENT_PAYMENT_METHOD],
    [MockRunField.PAYMENT_TYPE, RunField.CLIENT_PAYMENT_TYPE],
    [MockRunField.PAYMENT_DELAY, RunField.CLIENT_PAYMENT_DELAY],
    [MockRunField.PLANNED_PAYMENT_DATETIME, RunField.CLIENT_PLANNED_PAYMENT_DATE],
    [MockRunField.PAYMENT_DELAY_TYPE, RunField.CLIENT_PAYMENT_DELAY_TYPE],
]);

export const CARRIER_CONTRACT_DEPENDENT_FIELDS = new Map<string, string>([
    [MockRunField.CONTRACT, RunField.CARRIER_CONTRACT],
    [MockQuoteField.PAYMENT_METHOD, QuoteField.CARRIER_PAYMENT_METHOD],
    [MockRunField.PAYMENT_TYPE, RunField.CARRIER_PAYMENT_TYPE],
    [MockRunField.PAYMENT_DELAY, RunField.CARRIER_PAYMENT_DELAY],
    [MockRunField.PLANNED_PAYMENT_DATETIME, RunField.CARRIER_PLANNED_PAYMENT_DATE],
    [MockRunField.PAYMENT_DELAY_TYPE, RunField.CARRIER_PAYMENT_DELAY_TYPE],
]);
