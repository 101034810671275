import React from 'react';
import {
    PATH_CP_ASSORTMENT,
    PATH_CP_ASSORTMENTS,
    PATH_CP_ASSORTMENT_HISTORY,
    PATH_VARIABLE_ASSORTMENT_ID,
} from '../../../routerPaths';
import ContextError from '../../form/ContextError';
import { DwForm, useFieldValue } from '../../form/DwForm';
import { FormConfig } from '../../form/formConfig';
import { AssortmentField } from './assortmentFields';
import { AssortmentModel } from '../../../model/assortment';
import assortments from '../../../api/assortments';
import { Outlet, generatePath } from 'react-router-dom';
import DwFormTabs from '../../form/DwFormTabs';
import util from '../../../common/util';

const config = FormConfig.builder<AssortmentModel>()
    .number(
        AssortmentField.ID,
        (m) => m.id,
        (m, v) => (m.id = v)
    )
    .requiredText(
        AssortmentField.VALUE,
        (m) => m.value,
        (m, v) => (m.value = v)
    )
    .requiredOption(
        AssortmentField.GRADE,
        (m) => m.grade,
        (m, v) => (m.grade = v)
    )
    .requiredOption(
        AssortmentField.UNIT,
        (m) => m.unit,
        (m, v) => (m.unit = v)
    )
    .requiredNumber(
        AssortmentField.QUANTITY,
        (m) => m.quantity,
        (m, v) => (m.quantity = v)
    )
    .boolean(
        AssortmentField.ACTIVE,
        (m) => m.active,
        (m, v) => (m.active = v)
    )
    .load((id) => assortments.find(id))
    .submit((model) => assortments.save(model))
    .redirectUrl(PATH_CP_ASSORTMENTS)
    .idPathVariableName(PATH_VARIABLE_ASSORTMENT_ID)
    .build();

const AssortmentForm: React.FC = () => {
    return (
        <DwForm config={config}>
            <AssortmentLayout />
        </DwForm>
    );
};

const AssortmentLayout: React.FC = () => {
    const id = useFieldValue(AssortmentField.ID);
    const assortmentName = useFieldValue(AssortmentField.VALUE);
    const isNew: boolean = !id;
    return (
        <>
            <DwFormTabs
                items={[
                    {
                        name: isNew ? 'Новая номенклатура' : assortmentName,
                        path: generatePath(PATH_CP_ASSORTMENT, {
                            [PATH_VARIABLE_ASSORTMENT_ID]: util.stringOrEmpty(id),
                        }),
                        end: true,
                    },
                    {
                        name: 'История',
                        path: generatePath(PATH_CP_ASSORTMENT_HISTORY, {
                            [PATH_VARIABLE_ASSORTMENT_ID]: util.stringOrEmpty(id),
                        }),
                        end: true,
                        disabled: isNew,
                    },
                ]}
            />
            <ContextError />
            <Outlet />
        </>
    );
};

export default AssortmentForm;
