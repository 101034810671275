import { deleteRequest, get, post, put } from './rest';

import type { Option } from '../component/control/option';
import type {
    ContactModel,
    ClientContactRequest,
    CompanyContactRequest,
    StorageContactRequest,
} from '../model/contact/contactModel';
import type { ListRequest } from '../component/table/listRequest';
import type { PagedResponse } from './pagedResponse';
import type { ContactListModel } from '../model/contact/contactListModel';
import type { ApiResponse } from './apiResponse';

const PATH_BASE = 'contacts';

const contacts = {
    delete: (id: number) => deleteRequest<ApiResponse>(`${PATH_BASE}/${id}`),

    companyOptions: (request: CompanyContactRequest) =>
        post<Option[]>(`${PATH_BASE}/options/company`, request),

    clientOptions: (request: ClientContactRequest) =>
        post<Option[]>(`${PATH_BASE}/options/client`, request),
    listForClient: (model: ListRequest, clientId: number) =>
        post<PagedResponse<ContactListModel>>(`${PATH_BASE}/list?clientId=${clientId}`, model),
    getForClient: (id: number, clientId: number) =>
        get<ContactModel>(`${PATH_BASE}/${id}?clientId=${clientId}`),
    saveForClient: (model: ContactModel, clientId: number) =>
        post<ApiResponse>(`${PATH_BASE}?clientId=${clientId}`, model),

    listForStorage: (model: ListRequest, storageId: number) =>
        post<PagedResponse<ContactListModel>>(`${PATH_BASE}/storage/list?storageId=${storageId}`, model),
    getForStorage: (id: number, storageId: number) =>
        get<ContactModel>(`${PATH_BASE}/storage/${id}?storageId=${storageId}`),
    saveForStorage: (model: ContactModel, storageId: number) => {
        const url = `${PATH_BASE}/storage?storageId=${storageId}`;
        return model.id > 0 ? put<number>(url, model) : post<number>(url, model);
    },
    storageOptions: (request: StorageContactRequest) =>
        post<Option[]>(`${PATH_BASE}/options/storage`, request),
};

export default contacts;
