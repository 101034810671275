import type { ReactElement } from "react";
import React, { useCallback, useContext, useMemo } from "react";
import { useClickOutsideRef } from "../../common/clickOutside";
import { ClientType } from "../../model/client/clientModel";
import { Role } from "../../model/role";
import BooleanSelect from "../form/BooleanSelect";
import Clients from "../form/Clients";
import Companies from "../form/Companies";
import DtPicker from "../form/DtPicker";
import { FieldType } from "../form/fieldType";
import NumberInput from "../form/NumberInput";
import Options from "../form/Options";
import StringInput from "../form/StringInput";
import Users from "../form/Users";
import { ActionType } from "./action";
import type { Column } from "./column";
import { COLUMN_TO_OPTION_TYPE, ColumnType } from "./columnType";
import { TableContext } from "./DwTable";
import type { Filter } from "./filter";
import {
    BooleanFilter,
    DateFilter,
    DateTimeFilter,
    DecimalFilter,
    FilterType,
    IntegerFilter,
    OptionFilter,
    StringFilter
} from "./filter";
import { FilterForm } from "./FilterForm";
import Roles from "../form/Roles";
import EdiPartyOptions from "../form/EdiPartyOptions";
import {Storages} from '../form/ClientStorages';
import { Tmc } from "../form/Tmc";

const DEFAULT_WIDTH = 200;
const DEFAULT_MARGIN = 10;
const DEFAULT_HEIGHT = 90;

const getFilterType = (col: Column<any, any>): FilterType => {
    switch (col.type) {
        case ColumnType.PAYMENT_TYPE:
        case ColumnType.QUOTE_STATUS:
        case ColumnType.NDS_MODE:
        case ColumnType.CLIENT:
        case ColumnType.CARRIER:
        case ColumnType.SUPPLIER:
        case ColumnType.COMPANY:
        case ColumnType.LOGISTIC_MANAGER:
        case ColumnType.COMMERCIAL_MANAGER:
        case ColumnType.CLIENT_TYPE:
        case ColumnType.CLIENT_KIND:
        case ColumnType.CLIENT_STATUS:
        case ColumnType.CONTRACT_TYPE:
        case ColumnType.CONTRACT_STATUS:
        case ColumnType.RUN_STATUS:
        case ColumnType.RUN_TYPE:
        case ColumnType.TTN_STATUS:
        case ColumnType.UNIT:
        case ColumnType.OPERATION_TYPE:
        case ColumnType.QUOTE_SOURCE:
        case ColumnType.APPROVAL_STATUS:
        case ColumnType.STORAGE_STATUS:
        case ColumnType.PAYMENT_METHOD:
        case ColumnType.ROLE:
        case ColumnType.TMC_CATEGORY:
        case ColumnType.EDI_PARTY:
        case ColumnType.STORAGE:
        case ColumnType.TMC:
            return FilterType.OPTION;
        case ColumnType.INTEGER:
        case ColumnType.ID:
            return FilterType.INTEGER;
        case ColumnType.MONEY:
            return FilterType.DECIMAL;
        case ColumnType.DATE:
            return FilterType.DATE;
        case ColumnType.DATE_TIME:
            return FilterType.DATE_TIME;
        case ColumnType.BOOLEAN:
            return FilterType.BOOLEAN;
        default:
            return FilterType.STRING;
    }
};

const getFilterModel = (col: Column<any, any>, filters: Filter[]): Filter => {
    return filters.find((it) => it.field === col.field) ?? createFilter(col, getFilterType(col));
};

const calculateHeight = (filterType: FilterType) => {
    switch (filterType) {
        case FilterType.BOOLEAN:
            return '12%';
        case FilterType.DATE:
        case FilterType.DATE_TIME:
        case FilterType.INTEGER:
        case FilterType.DECIMAL:
            return '140px';
        default:
            return DEFAULT_HEIGHT;
    }
};

const calculateWidth = (filterType: FilterType) => {
    switch (filterType) {
        case FilterType.BOOLEAN:
            return 190;
        case FilterType.OPTION:
            return 300;
        default:
            return DEFAULT_WIDTH;
    }
};

const createFilter = (column: Column<any, any>, filterType: FilterType): Filter => {
    switch (filterType) {
        case FilterType.OPTION:
            return new OptionFilter(column.field);
        case FilterType.BOOLEAN:
            return new BooleanFilter(column.field);
        case FilterType.INTEGER:
            return new IntegerFilter(column.field);
        case FilterType.DECIMAL:
            return new DecimalFilter(column.field);
        case FilterType.DATE:
            return new DateFilter(column.field);
        case FilterType.DATE_TIME:
            return new DateTimeFilter(column.field);
    }
    return new StringFilter(column.field);
};

export const FilterPopup: () => ReactElement = () => {
    const context = useContext(TableContext);
    const position = context.state.position;
    const column = context.state.showFilterFor!;
    const {withEmptyValuesFilter} = column;
    const filterType = useMemo(() => getFilterType(column), [column]);
    const close = useCallback(() => context.dispatch({type: ActionType.DISPLAY_FILTER}), [context]);
    const { ref: filter } = useClickOutsideRef<HTMLDivElement>(close);
    const model = useMemo(
        () => getFilterModel(column, context.state.request.filters),
        [column, context.state.request.filters]
    );

    const style = useMemo(() => {
        const width = calculateWidth(filterType);
        const height = calculateHeight(filterType);
        const left = position?.x ?? 0;
        return {
            width: `${width}px`,
            height: withEmptyValuesFilter ? 'auto' : height,
            top: position?.y,
            left: left - Math.max(0, left + width + DEFAULT_MARGIN - window.innerWidth),
        };
    }, [position, filterType, withEmptyValuesFilter]);

    const renderIntegers = () => {
        return (
            <FilterForm close={close} type={FieldType.NUMBER} fields={['from', 'to']} {...{model, withEmptyValuesFilter}}>
                <NumberInput id={'from'} placeholder={'от'}/>
                <NumberInput id={'to'} placeholder={'до'}/>
            </FilterForm>
        );
    };

    const renderDates = () => {
        return (
            <FilterForm close={close} type={FieldType.DATE} fields={['from', 'to']} model={model}>
                <DtPicker id={'from'} placeholder={'от'}/>
                <DtPicker id={'to'} placeholder={'до'}/>
            </FilterForm>
        );
    };

    const renderOptions = () => {
        return (
            <FilterForm close={close} type={FieldType.OPTION} fields={['value']} model={model}>
                <Options id={'value'} dictionaryType={COLUMN_TO_OPTION_TYPE.get(column.type)!}/>
            </FilterForm>
        );
    };

    const renderString = () => {
        return (
            <FilterForm close={close} type={FieldType.OPTION} fields={['value']} {...{model, withEmptyValuesFilter}}>
                <StringInput id={'value'} placeholder={column.label}/>
            </FilterForm>
        );
    };

    const renderLogisticManagers = () => {
        return (
            <FilterForm close={close} type={FieldType.OPTION} fields={['value']} model={model}>
                <Users id={'value'} role={Role.LOGISTICIAN}/>
            </FilterForm>
        );
    };

    const renderCommercialManagers = () => {
        return (
            <FilterForm close={close} type={FieldType.OPTION} fields={['value']} model={model}>
                <Users id={'value'} role={Role.COMMERCIAL}/>
            </FilterForm>
        );
    };

    const renderCompanies = () => {
        return (
            <FilterForm close={close} type={FieldType.OPTION} fields={['value']} model={model}>
                <Companies id={'value'} />
            </FilterForm>
        );
    };

    const renderClients = (clientTypes: ClientType[]) => {
        return (
            <FilterForm close={close} type={FieldType.OPTION} fields={['value']} model={model}>
                <Clients id={'value'} clientTypes={clientTypes}/>
            </FilterForm>
        );
    };

    const renderStorages = () => {
        return (
            <FilterForm close={close} type={FieldType.OPTION} fields={['value']} model={model}>
                <Storages id={'value'} />
            </FilterForm>
        );
    };

    const renderTmcs = () => {
        return (
            <FilterForm close={close} type={FieldType.OPTION} fields={['value']} model={model}>
                <Tmc id={'value'} />
            </FilterForm>
        );
    };

    const renderEdiParty = () => {
        return (
            <FilterForm close={close} type={FieldType.OPTION} fields={['value']} model={model}>
                <EdiPartyOptions id={'value'}/>
            </FilterForm>
        );
    };

    const renderRoles = () => {
        return (
            <FilterForm close={close} type={FieldType.OPTION} fields={['value']} model={model}>
                <Roles id={'value'} />
            </FilterForm>
        );
    };

    const renderBoolean = () => {
        return (
            <FilterForm close={close} type={FieldType.BOOLEAN} fields={['value']} model={model}>
                <BooleanSelect id={'value'}/>
            </FilterForm>
        );
    };

    const renderControl = () => {
        if ([ColumnType.MONEY, ColumnType.INTEGER, ColumnType.ID].includes(column.type)) {
            return renderIntegers();
        } else if ([ColumnType.DATE, ColumnType.DATE_TIME].includes(column.type)) {
            return renderDates();
        } else if (
            [
                ColumnType.QUOTE_STATUS,
                ColumnType.QUOTE_SOURCE,
                ColumnType.NDS_MODE,
                ColumnType.PAYMENT_TYPE,
                ColumnType.CLIENT_TYPE,
                ColumnType.CLIENT_KIND,
                ColumnType.CLIENT_STATUS,
                ColumnType.CONTRACT_TYPE,
                ColumnType.CONTRACT_STATUS,
                ColumnType.RUN_STATUS,
                ColumnType.RUN_TYPE,
                ColumnType.TTN_STATUS,
                ColumnType.UNIT,
                ColumnType.OPERATION_TYPE,
                ColumnType.APPROVAL_STATUS,
                ColumnType.STORAGE_STATUS,
                ColumnType.PAYMENT_METHOD,
                ColumnType.TMC_CATEGORY,
            ].includes(column.type)
        ) {
            return renderOptions();
        } else if (ColumnType.ROLE === column.type) {
            return renderRoles();
        } else if (ColumnType.LOGISTIC_MANAGER === column.type) {
            return renderLogisticManagers();
        } else if (ColumnType.COMMERCIAL_MANAGER === column.type) {
            return renderCommercialManagers();
        } else if (ColumnType.COMPANY === column.type) {
            return renderCompanies();
        } else if (ColumnType.CLIENT === column.type) {
            return renderClients([
                ClientType.CLIENT,
                ClientType.CLIENT_OR_CARRIER,
                ClientType.CLIENT_OR_SUPPLIER,
            ]);
        } else if (ColumnType.CARRIER === column.type) {
            return renderClients([ClientType.CARRIER, ClientType.CLIENT_OR_CARRIER]);
        } else if (ColumnType.SUPPLIER === column.type) {
            return renderClients([ClientType.SUPPLIER]);
        } else if (ColumnType.STORAGE === column.type) {
            return renderStorages();
        } else if (ColumnType.TMC === column.type) {
            return renderTmcs();
        } else if (ColumnType.EDI_PARTY === column.type) {
            return renderEdiParty();
        } else if (ColumnType.BOOLEAN === column.type) {
            return renderBoolean();
        }
        return renderString();
    };

    return (
        <div className='filter' style={style} ref={filter}>
            {renderControl()}
        </div>
    );
};
