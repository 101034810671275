export type DictionaryModel = {
    id: number;
    value: string;
};

export type Option = {
    value: number;
    label: string;
    subOption?: Option;
};

export const DEFAULT_OPTION: Option = {
    value: 0,
    label: '',
};

export const NULL_OPTION: Option = {
    value: -1
} as Option;

export enum DictionaryType {
    CLIENT_KIND = 'CLIENT_KIND',
    CLIENT_TYPE = 'CLIENT_TYPE',
    PAYMENT_TYPE = 'PAYMENT_TYPE',
    PAYMENT_METHOD = 'PAYMENT_METHOD',
    NDS_MODE = 'NDS_MODE',
    QUOTE_STATUS = 'QUOTE_STATUS',
    CLIENT_STATUS = 'CLIENT_STATUS',
    CONTRACT_TYPE = 'CONTRACT_TYPE',
    CONTRACT_STATUS = 'CONTRACT_STATUS',
    DELAY_TYPE = 'DELAY_TYPE',
    CARCASS = 'CARCASS',
    CARGO_TYPE = 'CARGO_TYPE',
    ASSORTMENT = 'ASSORTMENT',
    DOCUMENT_SET = 'DOCUMENT_SET',
    GRADE = 'GRADE',
    LOADING_TYPE = 'LOADING_TYPE',
    TTN_STATUS = 'TTN_STATUS',
    QUOTE_SOURCE = 'QUOTE_SOURCE',
    RUN_TYPE = 'RUN_TYPE',
    RUN_STATUS = 'RUN_STATUS',
    VEHICLE_TYPE = 'VEHICLE_TYPE',
    VEHICLE_OWNERSHIP = 'VEHICLE_OWNERSHIP',
    APPROVAL_STATUS = 'APPROVAL_STATUS',
    UNIT = 'UNIT',
    EXPENSE_ITEM = 'EXPENSE_ITEM',
    OPERATION_TYPE = 'OPERATION_TYPE',
    STORAGE_TYPE = 'STORAGE_TYPE',
    ATTACHMENT_KIND = 'ATTACHMENT_KIND',
    STORAGE_STATUS = 'STORAGE_STATUS',
    ROLE = 'ROLE',
    TMC_CATEGORY = 'TMC_CATEGORY',
}

export const DictionaryNames: Map<DictionaryType, string> = new Map([
    [DictionaryType.CARCASS, 'Тип кузова'],
    [DictionaryType.CARGO_TYPE, 'Тип груза'],
    [DictionaryType.GRADE, 'Сорт'],
    [DictionaryType.UNIT, 'Единицы измерения'],
    [DictionaryType.LOADING_TYPE, 'Тип загрузки'],
    [DictionaryType.DOCUMENT_SET, 'Сопроводительные док-ты'],
    [DictionaryType.VEHICLE_TYPE, 'Тип ТС'],
    [DictionaryType.VEHICLE_OWNERSHIP, 'Тип собственности'],
    [DictionaryType.EXPENSE_ITEM, 'Статья расходов'],
    [DictionaryType.PAYMENT_METHOD, 'Метод оплаты'],
    [DictionaryType.NDS_MODE, 'Режим НДС'],
]);
