import { Unit } from '../../../model/enums/Unit';
import { Option } from '../../control/option';
import { AbstractEntity } from '../../../model/abstractEntity';
import util from '../../../common/util';

export type ShipmentDetailModel = AbstractEntity & {
    position: number;
    companyAssortment: Option;
    clientAssortment: Option;
    buyPrice: number;
    netPack: boolean;
    quantum: number;
    packagePerTare: number;
    sellPrice: number;
    weight: number;
    tareQuantity: number;
    totalCost: number;
    weightUnloaded: number;
    weightAccepted: number;
    weightLoaded: number;
    temperatureRegime: string;
    description: string;
    packageTmc: Option;
    tareTmc: Option;
    unit: Option;
    quantity: number;
    tmc: Option;
    talmanSeal: string;
    talmanDatetime: Date;
};

export const calcTotalCost = (row: ShipmentDetailModel): string => {
    const amount: number = ((): number => {
        if (row.unit?.value === Unit.PIECE) {
            return row.quantity ?? 0;
        }
        return row.weight ?? 0;
    })();

    const sellPrice = row.sellPrice ?? 0;
    const totalCost = amount * sellPrice;
    return totalCost.toFixed(2);
};

export const calcCargoPrice = (shipmentDetails: ShipmentDetailModel[]): number =>
    util.calcSum(...shipmentDetails.map(sd => Number(calcTotalCost(sd))));

export enum ShipmentDetailType {
    ASSORTMENT = 1,
    TMC = 2,
}

export const ShipmentDetailTypeNames: Map<ShipmentDetailType, string> = new Map([
    [ShipmentDetailType.ASSORTMENT, 'Номенклатура'],
    [ShipmentDetailType.TMC, 'ТМЦ'],
]);
